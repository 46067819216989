import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Loading from './components/Loading';

const HomePage = lazy(() => import('./HomePage'));
const About = lazy(() => import('./pages/about'));
const Krishna = lazy(() => import('./pages/krishna'));
const Contact = lazy(() => import('./pages/contact'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsConditions = lazy(() => import('./pages/TermsConditions'));
const ComingSoon = lazy(() => import('./pages/ComingSoon'));
const Finance = lazy(() => import('./pages/finance'));
const OtherServices = lazy(() => import('./pages/OtherServices'));
const CreditCards = lazy(() => import('./pages/finance/CreditCards'));
const AmericanExpress = lazy(() => import('./pages/finance/AmericanExpress/AmericanExpress'));
const AmericanExpressMembershipRewardsCreditCard = lazy(() => import('./pages/finance/AmericanExpress/AmericanExpressMembershipRewardsCreditCard'));
const AmericanExpressSmartEarnCreditCard = lazy(() => import('./pages/finance/AmericanExpress/AmericanExpressSmartEarnCreditCard'));
const AUSmallFinanceBank = lazy(() => import('./pages/finance/AUSmallFinanceBank/AUSmallFinanceBank'));
const AUAlturaCreditCard = lazy(() => import('./pages/finance/AUSmallFinanceBank/AUAlturaCreditCard'));
const AUAlturaPlusCreditCard = lazy(() => import('./pages/finance/AUSmallFinanceBank/AUAlturaPlusCreditCard'));
const AULITCreditCard = lazy(() => import('./pages/finance/AUSmallFinanceBank/AULITCreditCard'));
const AUSwipeUpPlatformCreditCard = lazy(() => import('./pages/finance/AUSmallFinanceBank/AUSwipeUpPlatformCreditCard'));
const AxisBank = lazy(() => import('./pages/finance/AxisBank/AxisBank'));
const AxisAtlasCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisAtlasCreditCard'));
const AxisFlipkartCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisFlipkartCreditCard'));
const AxisIOCLRupayCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisIOCLRupayCreditCard'));
const AxisLICPlatinumCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisLICPlatinumCreditCard'));
const AxisLICSignatureCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisLICSignatureCreditCard'));
const AxisMagnusCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisMagnusCreditCard'));
const AxisMyZoneCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisMyZoneCreditCard'));
const AxisNeoCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisNeoCreditCard'));
const AxisPrivilegeCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisPrivilegeCreditCard'));
const AxisVistaraInfiniteCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisVistaraInfiniteCreditCard'));
const AxisVistaraPlatinumCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisVistaraPlatinumCreditCard'));
const AxisVistaraSignatureCreditCard = lazy(() => import('./pages/finance/AxisBank/AxisVistaraSignatureCreditCard'));
const HDFCBank = lazy(() => import('./pages/finance/HDFCBank/HDFCBank'));
const HDFCBankCreditCard = lazy(() => import('./pages/finance/HDFCBank/HDFCBankCreditCard'));
const HDFCBankIRCTCCreditCard = lazy(() => import('./pages/finance/HDFCBank/HDFCBankIRCTCCreditCard'));
const HDFCBankMarriottCreditCard = lazy(() => import('./pages/finance/HDFCBank/HDFCBankMarriottCreditCard'));
const HDFCBankShoppersStopCreditCard = lazy(() => import('./pages/finance/HDFCBank/HDFCBankShoppersStopCreditCard'));
const HDFCBankSwiggyCreditCard = lazy(() => import('./pages/finance/HDFCBank/HDFCBankSwiggyCreditCard'));
const HDFCBankTataNeuPlusCreditCard = lazy(() => import('./pages/finance/HDFCBank/HDFCBankTataNeuPlusCreditCard'));
const HDFCBankUPIRuPayCreditCard = lazy(() => import('./pages/finance/HDFCBank/HDFCBankUPIRuPayCreditCard'));
const HSBC = lazy(() => import('./pages/finance/HSBC/HSBC'));
const HSBCCashbackCreditCard = lazy(() => import('./pages/finance/HSBC/HSBCCashbackCreditCard'));
const IDFCFirstBank = lazy(() => import('./pages/finance/IDFCFirstBank/IDFCFirstBank'));
const IDFCFirstCreditCard = lazy(() => import('./pages/finance/IDFCFirstBank/IDFCFirstCreditCard'));
const IDFCFirstPowerCreditCard = lazy(() => import('./pages/finance/IDFCFirstBank/IDFCFirstPowerCreditCard'));
const IDFCFirstPowerPlusCreditCard = lazy(() => import('./pages/finance/IDFCFirstBank/IDFCFirstPowerPlusCreditCard'));
const IDFCClubVistaraCreditCard = lazy(() => import('./pages/finance/IDFCFirstBank/IDFCClubVistaraCreditCard'));
const IndusIndBank = lazy(() => import('./pages/finance/IndusIndBank/IndusIndBank'));
const IndusIndLegendCreditCard = lazy(() => import('./pages/finance/IndusIndBank/IndusIndLegendCreditCard'));
const IndusIndPlatinumCreditCard = lazy(() => import('./pages/finance/IndusIndBank/IndusIndPlatinumCreditCard'));
const Kotak = lazy(() => import('./pages/finance/Kotak/Kotak'));
const KotakLeaguePlatinumCreditCard = lazy(() => import('./pages/finance/Kotak/KotakLeaguePlatinumCreditCard'));
const SBI = lazy(() => import('./pages/finance/SBI/SBI'));
const SBIBPCLCreditCard = lazy(() => import('./pages/finance/SBI/SBIBPCLCreditCard'));
const SBICashbackCreditCard = lazy(() => import('./pages/finance/SBI/SBICashbackCreditCard'));
const SBIIRCTCCreditCard = lazy(() => import('./pages/finance/SBI/SBIIRCTCCreditCard'));
const SBIPrimeCreditCard = lazy(() => import('./pages/finance/SBI/SBIPrimeCreditCard'));
const SBISimplyClickCreditCard = lazy(() => import('./pages/finance/SBI/SBISimplyClickCreditCard'));
const SBISimplySaveCreditCard = lazy(() => import('./pages/finance/SBI/SBISimplySaveCreditCard'));
const StandardChartered = lazy(() => import('./pages/finance/StandardChartered/StandardChartered'));
const StandardCharteredPlatinumRewardsCreditCard = lazy(() => import('./pages/finance/StandardChartered/StandardCharteredPlatinumRewardsCreditCard'));
const StandardCharteredSmartCreditCard = lazy(() => import('./pages/finance/StandardChartered/StandardCharteredSmartCreditCard'));
const BankAccount = lazy(() => import('./pages/finance/BankAccount'));
const FiMoney = lazy(() => import('./pages/finance/FiMoney'));
const InsurancePolicy = lazy(() => import('./pages/finance/InsurancePolicy'));
const HDFCLife = lazy(() => import('./pages/finance/HDFCLife/HDFCLife'));
const HRServices = lazy(() => import('./pages/HRServices'));
const ITRFilling = lazy(() => import('./pages/ITRFilling'));
const GSTFilling = lazy(() => import('./pages/GSTFilling'));
const Catering = lazy(() => import('./pages/catering'));

function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/krishna" element={<Krishna />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/other-services" element={<OtherServices />} />
          <Route path="/finance/cc" element={<CreditCards />} />
          <Route path="/finance/cc/amex" element={<AmericanExpress />} />
          <Route path="/finance/cc/amex/membership-rewards" element={<AmericanExpressMembershipRewardsCreditCard />} />
          <Route path="/finance/cc/amex/smartearn" element={<AmericanExpressSmartEarnCreditCard />} />
          <Route path="/finance/cc/au" element={<AUSmallFinanceBank />} />
          <Route path="/finance/cc/au/altura" element={<AUAlturaCreditCard />} />
          <Route path="/finance/cc/au/altura-plus" element={<AUAlturaPlusCreditCard />} />
          <Route path="/finance/cc/au/lit" element={<AULITCreditCard />} />
          <Route path="/finance/cc/au/swipeup-platform" element={<AUSwipeUpPlatformCreditCard />} />
          <Route path="/finance/cc/axis" element={<AxisBank />} />
          <Route path="/finance/cc/axis/atlas" element={<AxisAtlasCreditCard />} />
          <Route path="/finance/cc/axis/flipkart" element={<AxisFlipkartCreditCard />} />
          <Route path="/finance/cc/axis/iocl-rupay" element={<AxisIOCLRupayCreditCard />} />
          <Route path="/finance/cc/axis/lic-platinum" element={<AxisLICPlatinumCreditCard />} />
          <Route path="/finance/cc/axis/lic-signature" element={<AxisLICSignatureCreditCard />} />
          <Route path="/finance/cc/axis/magnus" element={<AxisMagnusCreditCard />} />
          <Route path="/finance/cc/axis/myzone" element={<AxisMyZoneCreditCard />} />
          <Route path="/finance/cc/axis/neo" element={<AxisNeoCreditCard />} />
          <Route path="/finance/cc/axis/privilege" element={<AxisPrivilegeCreditCard />} />
          <Route path="/finance/cc/axis/vistara-infinite" element={<AxisVistaraInfiniteCreditCard />} />
          <Route path="/finance/cc/axis/vistara-platinum" element={<AxisVistaraPlatinumCreditCard />} />
          <Route path="/finance/cc/axis/vistara-signature" element={<AxisVistaraSignatureCreditCard />} />
          <Route path="/finance/cc/hdfc" element={<HDFCBank />} />
          <Route path="/finance/cc/hdfc/cc" element={<HDFCBankCreditCard />} />
          <Route path="/finance/cc/hdfc/irctc" element={<HDFCBankIRCTCCreditCard />} />
          <Route path="/finance/cc/hdfc/marriott" element={<HDFCBankMarriottCreditCard />} />
          <Route path="/finance/cc/hdfc/shoppers-stop" element={<HDFCBankShoppersStopCreditCard />} />
          <Route path="/finance/cc/hdfc/swiggy" element={<HDFCBankSwiggyCreditCard />} />
          <Route path="/finance/cc/hdfc/tata-neu-plus" element={<HDFCBankTataNeuPlusCreditCard />} />
          <Route path="/finance/cc/hdfc/upi-rupay" element={<HDFCBankUPIRuPayCreditCard />} />
          <Route path="/finance/cc/hsbc" element={<HSBC />} />
          <Route path="/finance/cc/hsbc/cashback" element={<HSBCCashbackCreditCard />} />
          <Route path="/finance/cc/idfc-first" element={<IDFCFirstBank />} />
          <Route path="/finance/cc/idfc-first/first" element={<IDFCFirstCreditCard />} />
          <Route path="/finance/cc/idfc-first/first-power" element={<IDFCFirstPowerCreditCard />} />
          <Route path="/finance/cc/idfc-first/first-power-plus" element={<IDFCFirstPowerPlusCreditCard />} />
          <Route path="/finance/cc/idfc-first/club-vistara" element={<IDFCClubVistaraCreditCard />} />
          <Route path="/finance/cc/indusind" element={<IndusIndBank />} />
          <Route path="/finance/cc/indusind/legend" element={<IndusIndLegendCreditCard />} />
          <Route path="/finance/cc/indusind/platinum" element={<IndusIndPlatinumCreditCard />} />
          <Route path="/finance/cc/kotak" element={<Kotak />} />
          <Route path="/finance/cc/kotak/league-platinum" element={<KotakLeaguePlatinumCreditCard />} />
          <Route path="/finance/cc/sbi" element={<SBI />} />
          <Route path="/finance/cc/sbi/bpcl" element={<SBIBPCLCreditCard />} />
          <Route path="/finance/cc/sbi/cashback" element={<SBICashbackCreditCard />} />
          <Route path="/finance/cc/sbi/irctc" element={<SBIIRCTCCreditCard />} />
          <Route path="/finance/cc/sbi/prime" element={<SBIPrimeCreditCard />} />
          <Route path="/finance/cc/sbi/simply-click" element={<SBISimplyClickCreditCard />} />
          <Route path="/finance/cc/sbi/simply-save" element={<SBISimplySaveCreditCard />} />
          <Route path="/finance/cc/sc" element={<StandardChartered />} />
          <Route path="/finance/cc/sc/platinum-rewards" element={<StandardCharteredPlatinumRewardsCreditCard />} />
          <Route path="/finance/cc/sc/smart" element={<StandardCharteredSmartCreditCard />} />
          <Route path="/finance/ac" element={<BankAccount />} />
          <Route path="/finance/ac/fi" element={<FiMoney />} />
          <Route path="/finance/ip" element={<InsurancePolicy />} />
          <Route path="/finance/ip/hdfc-life" element={<HDFCLife />} />
          <Route path="/hr-services" element={<HRServices />} />
          <Route path="/itr-filling" element={<ITRFilling />} />
          <Route path="/gst-filling" element={<GSTFilling />} />
          <Route path="/catering" element={<Catering />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;